<template>
  <div class="PageInfo">
    <div class="logo"></div>
    <div class="pageTitle" :style="{backgroundImage: `url('${pageTitle}')`}"/>
    <div class="clock">{{moment().format('YYYY-MM-DD HH:mm')}}</div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "PageInfo",
  props: {
    pageTitle: {
      required: false,
    }
  },
  data() {
    return {
      moment,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";
.PageInfo {
  display: flex;
  align-items: center;
  width: 100%;
  .logo{
    width: 364px;
    height: 89px;
    @include backgroundMix;
    background-image: url("../assets/images/dwlogo.png");
    @media(max-width: 1280px){
      width: 243px;
      height: 59px;
    }
  }

  .pageTitle{
    flex: 1;
    height: 64px;
    @include backgroundMix;
    @media(max-width: 1280px){
      height: 43px;
    }
  }

  .clock{
    font-size: 22px;
    font-family: Microsoft YaHei;
    color: #FFFFFF;
    margin-right: 32px;
    display: flex;
    align-items: center;
    @media(max-width: 1280px){
      font-size: 15px;
      margin-right: 21px;
    }
  }
  .clock::before{
    display: inline-block;
    margin-right: 15px;
    content: "";
    width: 30px;
    height: 30px;
    @include backgroundMix;
    background-image: url("../assets/images/zhong.png");
    @media(max-width: 1280px){
      margin-right: 4px;
      width: 20px;
      height: 20px;
    }
  }

}
</style>
